import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import useScrollToTop from './components/useScrollToTop';
import { AuthProvider } from './context/AuthContext';
import { HelmetProvider } from 'react-helmet-async';

// Lazy-loading components
const Header = React.lazy(() => import('./components/common/Header'));
const Footer = React.lazy(() => import('./components/common/Footer'));
const PrivateRoute = React.lazy(() => import('./components/common/PrivateRoute'));

// General Pages
const Home = React.lazy(() => import('./components/pages/Home'));
const Blogs = React.lazy(() => import('./components/pages/Blogs'));
const PostDetails = React.lazy(() => import('./components/pages/PostDetails'));
const DestinationBlogs = React.lazy(() => import('./components/pages/DestinationBlogs'));
const Destinations = React.lazy(() => import('./components/pages/Destinations'));
const Forums = React.lazy(() => import('./components/pages/Forums'));
const ForumTopics = React.lazy(() => import('./components/pages/ForumTopics'));
const TopicDetails = React.lazy(() => import('./components/pages/TopicDetails'));
const Activities = React.lazy(() => import('./components/pages/Activities'));
const ActivitiesListing = React.lazy(() => import('./components/pages/ActivitiesListing'));
const ActivityDetails = React.lazy(() => import('./components/pages/ActivityDetails'));
const Demo = React.lazy(() => import('./components/pages/Demo'));

// User Panel Pages
const UserDetails = React.lazy(() => import('./components/userpanelpages/UserDetails'));
const Profile = React.lazy(() => import('./components/userpanelpages/Profile'));
const Coins = React.lazy(() => import('./components/userpanelpages/Coins'));
const CreatePost = React.lazy(() => import('./components/userpanelpages/CreatePost'));
const AllPosts = React.lazy(() => import('./components/userpanelpages/AllPosts'));
const BookmarkDetails = React.lazy(() => import('./components/userpanelpages/BookmarkDetails'));
const BookmarkLists = React.lazy(() => import('./components/userpanelpages/BookmarkLists'));
const EditPost = React.lazy(() => import('./components/userpanelpages/EditPost'));
const CreateTopics = React.lazy(() => import('./components/userpanelpages/CreateTopics'));
const AllTopics = React.lazy(() => import('./components/userpanelpages/AllTopics'));
const AllActivities = React.lazy(() => import('./components/userpanelpages/AllActivities'));
const CreateActivity = React.lazy(() => import('./components/userpanelpages/CreateActivity'));
const EditActivity = React.lazy(() => import('./components/userpanelpages/EditActivity'));

// Auth Pages
const Login = React.lazy(() => import('./components/auth/Login'));
const Signup = React.lazy(() => import('./components/auth/Signup'));
const ForgotPassword = React.lazy(() => import('./components/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./components/auth/ResetPassword'));

// Email Verification Pages
const EmailVerification = React.lazy(() => import('./components/auth/EmailVerification'));
const ResendVerification = React.lazy(() => import('./components/auth/ResendVerification'));

const Layout = ({ children }) => {
  const location = useLocation();
  const hideHeaderFooterPaths = [
    '/login',
    '/signup',
    '/forgotpassword',
    '/resetpassword',
    '/verify/:id',
    '/resend-verification',
  ];

  return (
    <>
      {!hideHeaderFooterPaths.includes(location.pathname) && (
        <Suspense fallback={null}>
          <Header />
        </Suspense>
      )}

      {children}

      {!hideHeaderFooterPaths.includes(location.pathname) && (
        <Suspense fallback={null}>
          <Footer />
        </Suspense>
      )}
    </>
  );
};

function App() {
  useScrollToTop();

  return (
    <AuthProvider>
      <HelmetProvider>
        <Suspense fallback={null}>
          <Routes>
            {/* General Routes */}
            <Route path="/" element={<Layout><Home /></Layout>} />
            <Route path="/blogs" element={<Layout><Blogs /></Layout>} />
            <Route path="/demo" element={<Layout><Demo /></Layout>} />
            <Route path="/blog/:slug" element={<Layout><PostDetails /></Layout>} />
            <Route path="/destinations" element={<Layout><Destinations /></Layout>} />
            <Route path="/destinations/:slug" element={<Layout><DestinationBlogs /></Layout>} />
            <Route path="/forums" element={<Layout><Forums /></Layout>} />
            <Route path="/forum-topics/:slug" element={<Layout><ForumTopics /></Layout>} />
            <Route path="/topic-details/:slug" element={<Layout><TopicDetails /></Layout>} />
            <Route path="/activities" element={<Layout><Activities /></Layout>} />
            <Route path="/activities-listing" element={<Layout><ActivitiesListing /></Layout>} />
            <Route path="/activity-details/:slug" element={<Layout><ActivityDetails /></Layout>} />

            {/* User Panel Routes */}
            <Route path="/userdetails" element={<Layout><UserDetails /></Layout>} />
            <Route path="/profile" element={<PrivateRoute element={<Layout><Profile /></Layout>} />} />
            <Route path="/coins" element={<PrivateRoute element={<Layout><Coins /></Layout>} />} />
            <Route path="/create-post" element={<PrivateRoute element={<Layout><CreatePost /></Layout>} />} />
            <Route path="/all-posts" element={<PrivateRoute element={<Layout><AllPosts /></Layout>} />} />
            <Route path="/create-topics" element={<PrivateRoute element={<Layout><CreateTopics /></Layout>} />} />
            <Route path="/all-topics" element={<PrivateRoute element={<Layout><AllTopics /></Layout>} />} />
            <Route path="/bookmark-lists" element={<PrivateRoute element={<Layout><BookmarkLists /></Layout>} />} />
            <Route path="/bookmark-details/:slug" element={<PrivateRoute element={<Layout><BookmarkDetails /></Layout>} />} />
            <Route path="/edit-post/:postId" element={<PrivateRoute element={<Layout><EditPost /></Layout>} />} />
            <Route path="/all-activities" element={<PrivateRoute element={<Layout><AllActivities /></Layout>} />} />
            <Route path="/create-activity" element={<PrivateRoute element={<Layout><CreateActivity /></Layout>} />} />
            <Route path="/edit-activity/:activityId" element={<PrivateRoute element={<Layout><EditActivity /></Layout>} />} />

            {/* Auth Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword/:token" element={<ResetPassword />} />


            {/* Email Verification Routes */}
            <Route path="/verify/:id" element={<EmailVerification />} />
            <Route path="/resend-verification" element={<ResendVerification />} />
          </Routes>
        </Suspense>
      </HelmetProvider>
    </AuthProvider>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
