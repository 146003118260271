// useScrollHandling.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollHandling = () => {
  const location = useLocation();
  
  useEffect(() => {
    const handleScroll = () => {
      // If there's a hash in the URL, scroll to that element
      if (location.hash) {
        // Small delay to ensure content is loaded
        setTimeout(() => {
          const element = document.querySelector(location.hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 500); // Increased delay to ensure content loads
      } else {
        // If no hash, scroll to top
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    handleScroll();
  }, [location]); // This will run when location changes
};

export default useScrollHandling;