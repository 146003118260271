import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    user: null, // Consider fetching user data here
    token: '',
  });

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthState({
        isAuthenticated: true,
        user: null, // Fetch user data here if necessary
        token,
      });
    }
  }, []);

  useEffect(() => {
    if (authState.token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${authState.token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [authState.token]);

  useEffect(() => {
    let timer;

    const startLogoutTimer = () => {
      if (timer) {
        clearTimeout(timer);
      }

      timer = setTimeout(async () => {
        await logout();
      }, 6 * 60 * 60 * 1000); // 6 hours in milliseconds
    };

    const handleUserActivity = () => {
      startLogoutTimer();
    };

    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    startLogoutTimer();

    return () => {
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [authState.isAuthenticated]);

  const login = (token) => {
    localStorage.setItem('authToken', token);
    setAuthState({
      isAuthenticated: true,
      user: null, // Fetch user data here if necessary
      token,
    });
  };

  const logout = async () => {
    try {
      await axios.post('https://www.ghoombuddy.com/backend/public/api/logout', {}, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
        }
      });
    } catch (error) {
      console.error('Logout API call failed', error);
    } finally {
      localStorage.removeItem('authToken');
      setAuthState({
        isAuthenticated: false,
        user: null,
        token: '',
      });
    }
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
 